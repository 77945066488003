/* eslint-disable no-console */
import { AxiosError, AxiosResponse } from 'axios'

export function getResponseErrors(res: AxiosResponse): string[] | null {
  let errors = null

  const data = res.data as any

  if (data && data.errors) {
    errors = data.errors
  }

  return errors
}

export function getResponseErrorMessage(res: AxiosResponse | AxiosError) {
  let response
  let isError = false

  if ('response' in res) {
    response = res.response as AxiosResponse
    isError = true
  } else {
    response = res as AxiosResponse
  }

  const errors = getResponseErrors(response)

  if (errors && errors[0]) {
    return errors[0]
  }

  if (isError || (response.status >= 400 && response.status <= 599)) {
    if (res.response) {
      return res.response.data!.error
    }
    return 'Ocorreu um problema ao trocar dados com o servidor'
  }

  return ''
}

export function handleCatchError(error: any) {
  if (error.response) {
    // The request was made and the server responded with a status code
  } else if (error.request) {
    // The request was made but no response was received
  } else {
    // Something happened in setting up the request that triggered an Error
  }
}
